
















































































import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { TrashArea } from '@/services/api/trash-area';
import { TrashAreaItem, TrashAreaTrips, TrashAreaFilters } from '@/types';
import PrintFooter from '@/components/print/print-footer.vue';
import { TrashAreaTripsReport } from '@/services/api/report/trash-area/trash-area-trips-report';
import { API_QUERY_DATE_FORMAT, API_QUERY_DATETIME_FORMAT, PROJECT_TITLE } from '@/constants/Global';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'trash-area-form4',
  components: {
    PrintFooter,
    ModelSelect,
    DatePicker,
  },
  setup(props, { root }) {
    const printing = ref(false);

    const data: {
      trashAreaTrips: Array<TrashAreaTrips>,
    } = reactive({
      trashAreaTrips: [],
    });
    const { current, isCurrentGeneral } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);
    const trashArea: {
      data: TrashAreaItem[],
    } = reactive({
      data: [],
    });
    const filter: {
      date: string[] | Date[],
      availability: {
        value?: number,
        text?: string,
      },
    } = reactive({
      date: ['', ''],
      availability: {},
    });

    const availabilityOptions = computed(() => {
      return trashArea.data.map((item) => {
        return {
          value: item.id,
          text: item.address,
        };
      });
    });

    if (Object.keys(root.$route.query).length) {
      const routeQuery = root.$route.query;

      const trashAreaId = routeQuery.trash_area_id;

      if (trashAreaId) {
        filter.availability = {
          value: Number(trashAreaId),
        };
      }

      const dateFrom = routeQuery.date_from;
      const dateTo = routeQuery.date_to;
      if (dateFrom) {
        filter.date[0] = moment(dateFrom as string).toDate();
      }
      if (dateTo) {
        filter.date[1] = moment(dateTo as string).toDate();
      }
    }

    const trashAreaData = (): Promise<Array<TrashAreaItem>> => {
      const municipalityId = current.value ? current.value.id : 0;
      return new Promise((resolve, reject) => {
        TrashArea.getTrashAreas(municipalityId, {
          hasTripsForPeriod: {
            date_from: moment(filter.date[0]).format(API_QUERY_DATE_FORMAT),
            date_to: moment(filter.date[1]).format(API_QUERY_DATE_FORMAT),
          },
        } as TrashAreaFilters, false, []).then((res) => {
          trashArea.data = res.data?.trash_areas;
          if (filter.availability.value) {
            filter.availability.text = trashArea.data.find(t => Number(t.id) === Number(filter.availability.value))?.address;
          }
          resolve(trashArea.data);
        }).catch((err) => {
          reject(err);
        });
      });
    };

    const formattedDate = () => {
      const isSameDate = moment(filter.date[0]).isSame(moment(filter.date[1]));
      if (isSameDate) return moment(filter.date[0]).format('DD.MM.YYYY');

      return `${moment(filter.date[0]).format('DD.MM.YYYY')} - ${moment(filter.date[1]).format('DD.MM.YYYY')}`;
    };

    const getData = () => {
      const query = {
        date_from: moment(filter.date[0]).startOf('day').format(API_QUERY_DATETIME_FORMAT),
        date_to: moment(filter.date[1]).endOf('day').format(API_QUERY_DATETIME_FORMAT),
        trash_area_id: filter.availability.value,
      };

      if (root.$route.query.trash_area_id?.toString() !== query.trash_area_id?.toString() ||
        root.$route.query.date_from?.toString() !== query.date_from?.toString() ||
        root.$route.query.date_to?.toString() !== query.date_to?.toString()) {
        root.$router.replace({
          query: query as any,
        }).catch(() => {});
      }

      TrashAreaTripsReport.getReport({
        trashAreaId: query.trash_area_id,
        dateFrom: query.date_from,
        dateTo: query.date_to,
      })
        .then((trashAreaTips) => {
          data.trashAreaTrips = trashAreaTips;
        })
        .catch(() => {});
    };

    const init = () => {
      if (!filter.date[0].toString().length) {
        return;
      }
      trashAreaData();
      getData();
    };

    init();

    watch(() => filter.availability, () => {
      if (!filter.availability.value) {
        return;
      }
      getData();
    });

    const reloadTrashAreas = () => {
      let selectedTrashArea = null;
      trashAreaData().then((trashAreas: Array<TrashAreaItem>) => {
        selectedTrashArea = trashAreas.find((ta) => {
          return ta.id === filter.availability.value;
        });
        if (!selectedTrashArea) {
          filter.availability.value = 0;
          filter.availability.text = '';
          return;
        }
        getData();
      });
    };
    watch(() => filter.date, () => {
      reloadTrashAreas();
    });

    watch(current, () => {
      reloadTrashAreas();
    });
    return {
      data,
      availabilityOptions,
      current,
      filter,
      isCurrentGeneral,
      printing,
      formattedHistoryDate(datetime: string) {
        return moment(datetime).format(API_QUERY_DATE_FORMAT);
      },
      formattedDate,
      formattedDateTime(datetime: string) {
        return moment(datetime).format('DD.MM.YYYY HH:mm');
      },
      disabledAfter(currentSelectedDate: Date, selectedDates: Array<Date>) {
        const daysPeriod = 31;
        const newDate = moment(currentSelectedDate);
        const maxDate = moment();
        let isDisabledDate = false;

        if (selectedDates.length > 1) {
          const date1 = moment(selectedDates[1]).add(daysPeriod, 'days');
          const date2 = moment(selectedDates[0]).add(-daysPeriod, 'days');
          isDisabledDate = isDisabledDate || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        } else if (selectedDates.length === 1) {
          const date1 = moment(selectedDates[0]).add(daysPeriod, 'days');
          const date2 = moment(selectedDates[0]).add(-daysPeriod, 'days');
          isDisabledDate = isDisabledDate || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        }

        return !newDate.isSameOrBefore(maxDate) || isDisabledDate;
      },
      printReport() {
        const header: HTMLElement | null = document.querySelector('header.header');
        const content: HTMLElement | null = document.querySelector('.content');
        const report: HTMLElement | null = document.querySelector('#print-report');
        const sticky: NodeListOf<HTMLElement> = document.querySelectorAll('.table th');

        if (!header || !content || !report) {
          return;
        }

        header.hidden = true;
        content.style.margin = '0';
        report.style.overflow = 'visible';
        printing.value = true;
        document.title = `Отчет Форма № 4 │ ${formattedDate()}`;
        sticky.forEach(e => {
          e.style.position = 'static';
          e.style.borderTop = '1px solid #dee2e6';
        });

        setTimeout(() => {
          window.print();

          header.hidden = false;
          content.style.margin = '';
          report.style.overflow = '';
          printing.value = false;
          sticky.forEach(e => {
            e.style.position = 'sticky';
            e.style.borderTop = 'none';
          });
          document.title = PROJECT_TITLE;
        }, 1000);
      },
    };
  },
});
