var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper",attrs:{"id":"print-report"}},[(_vm.printing)?_c('div',[_c('h5',{staticClass:"text-right"},[_vm._v("Форма № 4")]),_c('h5',{staticClass:"text-center"},[_c('u',[_vm._v("Отчет истории вывоза ТКО "),(_vm.current.id)?_c('span',[_vm._v(" в МО "+_vm._s(_vm.current.name))]):_vm._e()])]),_c('h5',{staticClass:"text-right"},[_vm._v("на дату: "+_vm._s(_vm.formattedDate())+"г.")])]):_vm._e(),_c('h2',{staticClass:"text-center",attrs:{"hidden":_vm.printing}},[_vm._v("Форма № 4")]),_c('h1',{staticClass:"text-center",attrs:{"hidden":_vm.printing}},[_vm._v("Отчет истории вывоза ТКО "),(_vm.current.id)?_c('span',[_vm._v(" в МО "+_vm._s(_vm.current.name))]):_vm._e()]),_c('div',{staticClass:"filter-wrapper",attrs:{"hidden":_vm.printing}},[_c('div',{staticClass:"filter-item datepicker"},[_c('date-picker',{attrs:{"type":"date","range":"","range-separator":"—","lang":"ru","clearable":false,"disabled-date":_vm.disabledAfter,"first-day-of-week":1},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1),(_vm.filter.date[0])?_c('div',{staticClass:"filter-item"},[_c('model-select',{staticClass:"autocomplete",attrs:{"options":_vm.availabilityOptions,"placeholder":"ТКО"},model:{value:(_vm.filter.availability),callback:function ($$v) {_vm.$set(_vm.filter, "availability", $$v)},expression:"filter.availability"}})],1):_vm._e()]),(_vm.filter.availability.value)?_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table",attrs:{"border":"1"}},[_vm._m(0),(_vm.data.trashAreaTrips)?_c('tbody',[_vm._l((_vm.data.trashAreaTrips),function(trashArea,index){return [_c('tr',{key:index},[_c('td',{staticStyle:{"width":"50px"}},[_vm._v(" "+_vm._s(index + 1))]),_c('td',{staticStyle:{"width":"100px"}},[_c('router-link',{attrs:{"to":{
                name: 'kommun_transportation.history-detail',
                params: {
                  number: trashArea.transport.number,
                },
                query: {
                  date: _vm.formattedHistoryDate(trashArea.datetime),
                  transportId: trashArea.transport.id.toString(),
                  number: trashArea.transport.number,
                }
              },"target":"_blank"}},[_vm._v(" "+_vm._s(trashArea.transport.number.toUpperCase())+" ")])],1),_c('td',[(trashArea.transport.transporter)?[_vm._v(_vm._s(trashArea.transport.transporter.name))]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm.formattedDateTime(trashArea.datetime))+" ")])])]})],2):_vm._e()]),(_vm.printing)?_c('print-footer'):_vm._e()],1):_vm._e(),(_vm.filter.availability.value)?_c('div',{staticClass:"action-wrapper",attrs:{"hidden":_vm.printing}},[_c('div',{staticClass:"action-item"},[_c('button',{staticClass:"btn btn-warning btn-print",attrs:{"disabled":!_vm.filter.date[0]},on:{"click":function($event){$event.preventDefault();return _vm.printReport()}}},[_vm._v("Печать")])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"35px"}},[_vm._v("№ п/п")]),_c('th',[_vm._v("Номер машины")]),_c('th',[_vm._v("Оператор")]),_c('th',[_vm._v("Время и дата")])])])}]

export { render, staticRenderFns }