/* eslint-disable camelcase */
import axios from '@/services/axios';
import { AxiosResponse } from 'axios';
import { TrashAreaTrips } from '@/types';

export class TrashAreaTripsReport {
  static getReport({
    trashAreaId,
    dateFrom,
    dateTo,
  }: {
    trashAreaId?: number,
    dateFrom?: string,
    dateTo?: string,
  }): Promise<TrashAreaTrips[]> {
    const params: {
      trash_area_id?: number,
      date_to?: string,
      date_from?: string,
    } = {};
    if (trashAreaId) {
      params.trash_area_id = trashAreaId;
    }
    if (dateFrom) {
      params.date_from = dateFrom;
    }
    if (dateTo) {
      params.date_to = dateTo;
    }
    return axios.get('/report-trash-area/form4', { params })
      .then((response: AxiosResponse<{ trash_area_trips: TrashAreaTrips[] }>) => {
        return response.data.trash_area_trips;
      });
  }
}
